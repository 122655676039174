<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  name: "Home",
  created() {},
  computed: {},
};
</script>

<style scoped></style>
